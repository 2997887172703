







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.closeModal{
   position: absolute;
    right: 10px;
    top: 6px;
}
.video-player-jpeg{
  z-index: 5;
  position: relative;
a{
  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
     .play-circle{
    background-color: #1170cb;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
}
 
}
